
export class PCNRemarks {
 PCNID:number
 UserID:number
 UserName:string
 ApproverSeq:number
 Remark:string
 CreatedDate:Date
    
}
