import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from "@angular/common/http";
import { User } from '../Model/User';
import { Approver } from '../Model/Approver';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class UserService {
 
  editUser:User;
  constructor(private httpClient:HttpClient) { }
  
  lookupUsers(value:string)
  {
    //console.log(environment.apiEndPoint);
    return this.httpClient.get<Array<Approver>>(environment.apiEndPoint+"users/lookupuser/"+value);
  }
  getUsers()
  {
    return this.httpClient.get<Array<User>>(environment.apiEndPoint+"users");
  }
  deleteUser(userID)
  {
    return this.httpClient.delete<any>(environment.apiEndPoint+"users/"+userID);
  }
  saveUser(user:User)
  {
    return this.httpClient.post<User>(environment.apiEndPoint+"users",user);
  }
  
  getLoggedInUserID()
  {
    return 3;
  }
  getUserName()
  {
    return "Dhirendra Bisht";
  }
  getUserRole()
  {
    return "admin";
  }
}
