import { Approver } from './Approver';
import {  Attachment } from './Attachment';
import { PCNRemarks } from './Remark';

export class PCN {
    PCNID:number
    PCNNo:string
    ParentPCNID:number
    InitiatorID:number
    DeptID:number
    Priority:string
    DeptName:string
    SubVertical:string
    InitiatorName:string
    Justification:string
    Request:string
    Status:string
    ModifiedDate:Date
    FirstLevelApprover:Approver
    FirstApproverID:number
    CreatedDate:Date
    ModifiedBy:number
    CreatedBy:number
    IsApprover:boolean
    CanDelete:boolean
    CanEdit:boolean
    CanReview:boolean
    ApproversList:Array<Approver>   
    Attachments:Array<Attachment>
    RemarksList:Array<PCNRemarks>
}
    