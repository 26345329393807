import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEventType } from "@angular/common/http";
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { PCN } from "../../../Model/PCN";
import {TableModule} from 'primeng/table';
import { Approver } from "../../../Model/Approver";
import { AuthService } from '../../auth.service';
import { Attachment } from 'src/app/Model/Attachment';
import { PCNRemarks } from 'src/app/Model/Remark';

@Injectable({
  providedIn: 'root'
})
export class PCNService {

  constructor(private httpClient:HttpClient, private authSrv:AuthService) { }
  editPcnNo:number;
  editPCN:PCN
  
  getPCNDetail(isReview:boolean)
  {
    //alert("test");
    let temp=this.editPcnNo;
   // this.editPcnNo=0;
    let url=environment.apiEndPoint+"PCN/GetPCNDetails/"+temp+"/"+this.authSrv.loggedINUSer.UserID+"/"+isReview;
    console.log(url);
    return this.httpClient.get<PCN>(url);
  }
  GetMyPCNForApproval(id:number)
  {
   // alert("tes");
    return this.httpClient.get<PCN []>(environment.apiEndPoint+"PCN/GetMyPCN/"+id+"/pending");
  }
  getPCNList(id:number)
  {
    //console.log(environment.apiEndPoint);
    return this.httpClient.get<PCN []>(environment.apiEndPoint+"PCN/GetMyPCN/"+id+"/my");
  }
  UpdatePCN(request:PCN)
  {
    return this.httpClient.post<any>(environment.apiEndPoint+"PCN/UpdatePCN",request);
  }
  PostRemark(remark:PCNRemarks)
  {
    return this.httpClient.post<any>(environment.apiEndPoint+"PCN/SaveRemark",remark);
  }
  UploadAttachments(files,PCNID:number)
  {
    if (files.length === 0)
      return;

    const formData = new FormData();
    formData.append("PCNID",PCNID.toString());
    for (let file of files)
      formData.append(file.name, file);

    const uploadReq = new HttpRequest('POST', environment.apiEndPoint+`/PCN/UploadFile`, formData, {
      reportProgress: true,
    });

    return this.httpClient.post<Array<Attachment>>(environment.apiEndPoint+`/PCN/UploadFile`,formData);
    
    // this.httpClient.post<Array<Attachment>>(environment.apiEndPoint+`/PCN/UploadFile`,formData).subscribe( data=>{
    //   console.log("uploaded:"+data);
    // });
    // this.httpClient.request(uploadReq).subscribe(event => {
    //    if (event.type === HttpEventType.Response)
    //     {
    //       console.log("uploaded:"+event);
    //     }
    //    // this.message = event.body.toString();
    // });
  }
  DeleteAttachment(attID:number)
  {
    return this.httpClient.get<any>(environment.apiEndPoint+"PCN/DeleteAttchment/"+attID);
  }

  ResubmitPCN(request:PCN)
  {
    return this.httpClient.post<any>(environment.apiEndPoint+"PCN/ResubmitPCN",request);
  }
  
  SavePCN(request:PCN)
  {
      //const body=JSON.stringify(request);
     // return this.httpClient.get<any>(API_ENDPOINT+'PCNRequests');
      return this.httpClient.post<any>(environment.apiEndPoint+"PCN",request);

  }
  GetAllApprovers(userID :number){
    return this.httpClient.get<Approver[]>(environment.apiEndPoint+"masterapproverlist");
 }
 deletePCN(pcn :number){
   debugger;
  return this.httpClient.delete(environment.apiEndPoint+"PCN/"+pcn);
}
 getNewPCNNo()
 {
  return this.httpClient.get(environment.apiEndPoint+"GetNewPCNNO");
 }
}
