import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { MessageService } from 'primeng/api';
import { UserLogin } from 'src/app/Model/UserLogin';
import { tap, delay } from 'rxjs/operators';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['../login.component.css']
})
export class ChangepasswordComponent implements OnInit {
  token:string;
  user:UserLogin;
  isReset:boolean=false;
  constructor(private route:ActivatedRoute,public authService: AuthService, public router:Router,private messageService:MessageService) {
   if(!this.authService.isLoggedIn)
    this.token = this.route.snapshot.params.token;
   }

  ngOnInit() {
    //if(token!=null)this.isReset=true;
   // alert(this.token);
   this.user=new UserLogin();
  }
  changePassword()
  {
    if(!this.authService.isLoggedIn)
    this.user.Token=this.token;
    else 
    {
      this.user.UserID=this.authService.loggedINUSer.UserID;
      this.user.isChangePassword=true;
    }

   this.authService.ResetPassword(this.user).subscribe(data=>
    {
      this.messageService.add({severity:'info', summary:data.Message, detail:''});
     // delay(5000);
     // this.router.navigate(['/login']);
    }
    );
  }
  messageCloded(event)
  {
    this.router.navigate(['/login']); 
  } 
}
