import { Component } from '@angular/core';
import {  AfterViewInit } from '@angular/core';
import { AuthService } from './services/auth.service';
import { Observable, of, timer } from 'rxjs';
import { 
  Router, NavigationStart, NavigationCancel, NavigationEnd 
} from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit  {
  title = 'Ottomate Intranet';
  loading;
  blocked: boolean=true;
  isLoggedin:boolean;
  constructor(private authSrv:AuthService,private router:Router) { 
    
    this.loading = true;
  }
  
  ngOnInit() {
    this.isLoggedin=this.authSrv.isLoggedIn;
  }
  ngAfterViewInit() {
    this.router.events
        .subscribe((event) => {
            if(event instanceof NavigationStart) {
                this.loading = true;
            }
            else if (
                event instanceof NavigationEnd || 
                event instanceof NavigationCancel
                ) {
                 // alert("test");
                //   setTimeout(2000){}
                this.loading = false;
            }
        });
}
}
