import { Component, OnInit, Input } from '@angular/core';
import { PCNRemarks } from '../Model/Remark';
import { AuthService } from '../services/auth.service';
import { DatePipe } from '@angular/common';
import { PCNService } from '../services/requests/pcn/pcn.service';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.css']
})
export class CommentsComponent implements OnInit {
  @Input()lstRemarks:Array<PCNRemarks>;
  @Input()PCNID:number;
  @Input()CanPost:boolean=true;
  statusText;
  //canPostStatus=true;
  strRemark:string
  
  constructor(private authSrv:AuthService,private pcnSRV:PCNService) { 
    
   // this.lstRemarks=new Array<PCNRemarks>()[{Remark:"abc"}];
      
  }

  ngOnInit() {
    //alert(this.lstRemarks[0].UserName);
   // alert("2");
   //debugger;
  //  this.lstRemarks=new Array<PCNRemarks>();
  }
  PostComment()
  {
    debugger;
    let remark=new PCNRemarks();
    remark.PCNID=this.PCNID;
    remark.Remark=this.strRemark;
    remark.UserID=this.authSrv.loggedINUSer.UserID;
    remark.UserName=this.authSrv.getUserName();
    
    this.strRemark="";
    this.pcnSRV.PostRemark(remark).subscribe(data=>
      {
        remark=data;
        this.lstRemarks.push(data);
      }
      )
  }
}
