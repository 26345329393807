import { Component, OnInit } from '@angular/core';
import { PCNService } from 'src/app/services/requests/pcn/pcn.service';
import { Router, ActivatedRoute } from '@angular/router';
import { PCN } from 'src/app/Model/PCN';
import { Attachment } from 'src/app/Model/Attachment';
import { error } from 'util';
@Component({
  selector: 'app-review-pcninitiator',
  templateUrl: './review-pcninitiator.component.html',
  styleUrls: ['./review-pcninitiator.component.css']
})
export class ReviewPCNInitiatorComponent implements OnInit {

  editPCN:PCN
  isDataAvailable:boolean = false;

  
  //approver:Approver
  constructor(private route:ActivatedRoute, private pcnSrv:PCNService, private router:Router) {

    let temp=  this.route.snapshot.params.pcnid;
   
    if(temp!=null)
    pcnSrv.editPcnNo=temp;
   }

  ngOnInit() {
    
    this.pcnSrv.getPCNDetail(false).subscribe( data=>{
      this.editPCN=data;
      this.isDataAvailable=true;
    },
    error=>
    {
      this.router.navigateByUrl('/');
    }
    
    );
  }
  ResubmitPCN()
  {
    debugger;
    this.editPCN.Status="Resubmitted";
    this.editPCN.FirstLevelApprover.status="Resubmitted";
    this.pcnSrv.ResubmitPCN(this.editPCN).subscribe()
    {

    };
    this.router.navigateByUrl('/');
  }
  DeleteAttachment(attachment:Attachment)
  {
    this.pcnSrv.DeleteAttachment(attachment.PCNATTID).subscribe(data=>
      {
        const index: number = this.editPCN.Attachments.indexOf(attachment);
        if (index !== -1) {
          this.editPCN.Attachments.splice(index, 1);
        }        
       
      }
    );
  //  alert(attID);
  }

  uploadedFiles: any[] = [];
  uploadAttachment(event)
  {
    //alert("done");
    var filesToupload = [];
    for(let file of event.files) { 
      filesToupload.push(file);
     // this.uploadedFiles.push(file); 
    //  console.log(this.uploadedFiles); 
      } 
     this.pcnSrv.UploadAttachments(filesToupload,this.editPCN.PCNID).subscribe(data=>{
    //   debugger;
      // this.editPCN.Attachments=data;
     });

  }
}