import { Component, OnInit } from '@angular/core';
import { UserService  } from "../services/user.service";
import { PCNService  } from "../services/requests/pcn/pcn.service";
import { PCN } from "../Model/PCN";
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { Approver } from '../Model/Approver';
import { PCNRemarks } from '../Model/Remark';
@Component({
  selector: 'app-pending-for-approval',
  templateUrl: './pending-for-approval.component.html',
  styleUrls: ['./pending-for-approval.component.css']
})
export class PendingForApprovalComponent implements OnInit {
  constructor(private userSrv:UserService,private pcnSrv:PCNService,private authSrv:AuthService,private router:Router) { }
  pendingpcnList:PCN [];

  ngOnInit() {
   // console.log(this.pcnSrv.GetMyPCNForApproval(this.authSrv.loggedINUSer.UserID));
    this.pcnSrv.GetMyPCNForApproval(this.authSrv.loggedINUSer.UserID).subscribe(data=>{
      console.log(data)
      this.pendingpcnList=data;
      debugger;
    }) ;
  }
  review(pcnID)
  {
    this.pcnSrv.editPcnNo=pcnID;
    this.router.navigateByUrl('/review');
  }
  edit(pcn)
  {
    this.pcnSrv.editPCN=pcn;
    this.router.navigateByUrl('/newpcn');
  }
  display:boolean=false;
  lstApp:Array<Approver>;
  lstRemarks:Array<PCNRemarks>;

viewStatus(pcn:PCN)
{
 //   debugger;
  //  this.lstApp=pcn.ApproversList;
  //  this.lstRemarks=pcn.RemarksList;
   // alert(pcn.ApproversList[0].ApproverName);
  //  this.display = true;
   this.pcnSrv.editPcnNo=pcn.PCNID;
   this.router.navigateByUrl('/viewpcn');
    
  }

}
