import { Component, OnInit } from '@angular/core';
import { PCNService } from 'src/app/services/requests/pcn/pcn.service';
import { Router } from '@angular/router';
import { PCN } from 'src/app/Model/PCN';
import { Attachment } from 'src/app/Model/Attachment';
import { of } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common.service';
import { Approver } from 'src/app/Model/Approver';

@Component({
  selector: 'app-edit-pcn',
  templateUrl: './edit-pcn.component.html',
  styleUrls: ['./edit-pcn.component.css']
})
export class EditPCNComponent implements OnInit {
  editPCN:PCN
 
  constructor(private pcnSrv:PCNService,private commonSrv:CommonService, private router:Router,private authSrv:AuthService) { }

  ngOnInit() {
    
    this.pcnSrv.getPCNDetail(false).subscribe( data=>{
     // debugger;
      this.editPCN=data;
     // this.editPCN.PCNNo
      //this.approver=this.reviewPCN.ApproversList[0];
     // debugger;
     //console.log(this.editPCN.ApproversList.length);
    });
  }
  ResubmitPCN()
  {
    debugger;
    if(this.editPCN.Status!="Draft")
    this.editPCN.Status="Updated";
    
    this.editPCN.FirstLevelApprover.status="Resubmitted";
  
    this.authSrv.blocked=of(true);
    this.pcnSrv.ResubmitPCN(this.editPCN).subscribe()
    {
      this.authSrv.blocked=of(false );
    };
    this.router.navigateByUrl('/');
  }
  DeleteAttachment(attachment:Attachment)
  {
    this.pcnSrv.DeleteAttachment(attachment.PCNATTID).subscribe(data=>
      {
        const index: number = this.editPCN.Attachments.indexOf(attachment);
        if (index !== -1) {
          this.editPCN.Attachments.splice(index, 1);
        }        
       
      }
    );
  //  alert(attID);
  }
  uploadedFiles: any[] = [];
  uploadAttachment(event)
  {
    //alert("done");
    var filesToupload = [];
    for(let file of event.files) { 
      filesToupload.push(file);
     // this.uploadedFiles.push(file); 
    //  console.log(this.uploadedFiles); 
      } 
     this.pcnSrv.UploadAttachments(filesToupload,this.editPCN.PCNID).subscribe(data=>{
    //   debugger;
      // this.editPCN.Attachments=data;
     });

  }
approverChangedMan(approver:Approver)
{
  approver.FYI=false;
}
approverChangedFYI(approver:Approver)
{
  approver.Mandatory=false;
}
}
