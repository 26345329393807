import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {AccordionModule} from 'primeng/accordion';
import {MenuItem, MessageService} from 'primeng/api';
import {SelectItem} from 'primeng/api';
import {MenuModule} from 'primeng/menu';
import {PanelMenuModule} from 'primeng/panelmenu';
import { HeaderComponent } from './menu/header/header.component';
import { LeftComponent } from './menu/left/left.component';
import { AllRequestsComponent } from './Requests/List/all-requests/all-requests.component';
import { ReviewComponent } from './Requests/Edit/review/review.component';
import { CreateComponent } from './Users/Create/create/create.component';
import { ListComponent } from './Users/List/list/list.component';
import { EditComponent } from './Users/Editt/edit/edit.component';
import { TableModule } from 'primeng/table';
import {PanelModule} from 'primeng/panel';
import {ButtonModule} from 'primeng/button';
import { NewPCNComponent } from './Requests/PCN/new-pcn/new-pcn.component';
import {DropdownModule} from 'primeng/dropdown';
import {InputTextModule} from 'primeng/inputtext';
import {InputTextareaModule} from 'primeng/inputtextarea';
import { FormsModule,ReactiveFormsModule  } from '@angular/forms';
import {FileUploadModule} from 'primeng/fileupload';
import {CheckboxModule} from 'primeng/checkbox';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {ToastModule} from 'primeng/toast';
import { AuthModule }  from './auth/auth.module';
import {SplitButtonModule} from 'primeng/splitbutton';
import {FieldsetModule} from 'primeng/fieldset';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {DynamicDialogModule} from 'primeng/dynamicdialog';
import {DialogModule} from 'primeng/dialog';
import {ProgressBarModule} from 'primeng/progressbar';
import { PendingForApprovalComponent } from './pending-for-approval/pending-for-approval.component';
import { EditPCNComponent } from './Requests/edit-pcn/edit-pcn.component';
import { ViewPCNComponent } from './Requests/view-pcn/view-pcn.component';
import {BlockUIModule} from 'primeng/blockui';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {RadioButtonModule} from 'primeng/radiobutton';
import { CommentsComponent } from './comments/comments.component';
import { ReviewPCNInitiatorComponent } from './review-pcninitiator/review-pcninitiator.component';
import {Validators,FormControl,FormGroup,FormBuilder} from '@angular/forms';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ChangepasswordComponent } from './auth/login/changepassword/changepassword.component';
import {EditorModule} from 'primeng/editor';
//import { FormsModule, ReactiveFormsModule } from '@angular/forms';
//import {DropdownModule} from "primeng/components/dropdown/dropdown";
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LeftComponent,
    AllRequestsComponent,
    ReviewComponent,
    CreateComponent,
    ListComponent,
    EditComponent,
    NewPCNComponent,  
    PendingForApprovalComponent,
    EditPCNComponent,
    ViewPCNComponent,
    CommentsComponent,
    ReviewPCNInitiatorComponent,
    DashboardComponent,
    ChangepasswordComponent
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MenuModule,
    PanelMenuModule,
    AccordionModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TableModule,
    PanelModule,
    ButtonModule,
    DropdownModule,
    InputTextModule,
    InputTextareaModule,
    FormsModule,
    FileUploadModule,
    CheckboxModule,
    MessagesModule,
    MessageModule,
    ToastModule,
    AuthModule,
    SplitButtonModule,
    FieldsetModule,
    AutoCompleteModule,
    DynamicDialogModule,
    DialogModule,
    ProgressBarModule,
    BlockUIModule,
    ProgressSpinnerModule,
    RadioButtonModule,
    FormsModule,
    ReactiveFormsModule,
    ScrollPanelModule,
    EditorModule
  ],
  providers: [MessageService],
  bootstrap: [AppComponent]
})
export class AppModule { }
