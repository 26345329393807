import { NgModule }       from '@angular/core';
import { CommonModule }   from '@angular/common';
import { FormsModule }    from '@angular/forms';
import {Message} from 'primeng//api';
import {MessageService} from 'primeng/api';
import { LoginComponent }    from './login/login.component';
import { AppRoutingModule } from '../app-routing.module';
import { PanelModule } from 'primeng/panel';
import { ToastModule } from 'primeng/toast';
// import { ChangepasswordComponent } from './login/changepassword/changepassword.component';
import { ForgotpasswordComponent } from './login/forgotpassword/forgotpassword.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    AppRoutingModule,
    ToastModule 
  ],
  declarations: [
    LoginComponent,
    // ChangepasswordComponent,
    ForgotpasswordComponent
  ]
})
export class AuthModule {}