import { Component, OnInit } from '@angular/core';
import { PCNService } from 'src/app/services/requests/pcn/pcn.service';
import { PCN } from 'src/app/Model/PCN';
import { Approver } from 'src/app/Model/Approver';
import { ActivatedRoute, Router } from '@angular/router';
import { of } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.css']
})
export class ReviewComponent implements OnInit {
   reviewPCN:PCN
   approver:Approver
  constructor(private route:ActivatedRoute, private pcnSrv:PCNService, private router:Router,private authSrv:AuthService ) { 
    let temp=  this.route.snapshot.params.pcnid;
   // alert(temp);
    if(temp!=null)
    pcnSrv.editPcnNo=temp;
 //  pcnSrv.editPCN=
   // pcnid= this.route.snapshot.params.token;
  }

  ngOnInit() {
   // alert("hello ");
      this.pcnSrv.getPCNDetail(true).subscribe( data=>{
        this.reviewPCN=data;
       // debugger;
       // this.approver=this.reviewPCN.FirstLevelApprover;
       // console.log(this.reviewPCN);
      });
  }
  submit(status)
  {
   // alert(reviewPCN.FirstLevelApprover.Remarks);
    this.reviewPCN.Status=status
    this.reviewPCN.FirstLevelApprover.status=status;
   // this.reviewPCN.FirstLevelApprover.Remarks=this.approver.Remarks;
   // this.reviewPCN.ApproversList[0].Remarks
   this.authSrv.blocked=of(true);
    this.pcnSrv.UpdatePCN(this.reviewPCN).subscribe(data=>{
      this.authSrv.blocked=of(false);
      // this.messageService.add({severity:'success', summary:'New PCN Request Submitted!!', detail:''});
       this.router.navigateByUrl('/pending');
       // alert("PCN Saved!!");
       }) ;
  }
  
}
