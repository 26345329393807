import { Injectable } from '@angular/core';
import { Department } from "../Model/department";
import { HttpClient } from "@angular/common/http";
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private httpClient:HttpClient) { }

  GetAllDepartments(){
    return this.httpClient.get<Department[]>(environment.apiEndPoint+'Departments');
 }
 GetAPIEndPoint()
 {
  return environment.apiEndPoint;
 }
}
