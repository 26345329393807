import { Component }   from '@angular/core';
import { Router }      from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { User } from 'src/app/Model/User';
import { UserLogin } from 'src/app/Model/UserLogin';
import {Message} from 'primeng//api';
import {MessageService} from 'primeng/api';
import { of } from 'rxjs';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  // providers:[MessageService]
})
export class LoginComponent {
  message: string;
  loginUser:UserLogin;
  constructor(public authService: AuthService, public router: Router,private messageService:MessageService) {
    this.setMessage();
    this.loginUser=new UserLogin();
   // this.loginUser.UserName="dhirendrabisht@gmail.com";
   // this.loginUser.Password="changeme";
  }

  setMessage() {
    
    this.message = 'Logged ' + (this.authService.isLoggedIn ? 'in' : 'out');
  }

  login() {
  
    if(this.loginUser.UserName==null || this.loginUser.Password==null)
    {
      this.messageService.add({severity:'error', summary:'Please enter User Name and Password!!', detail:''});
    }
    else
    {
    this.authService.Authnticate(this.loginUser).subscribe( data=>
      {
          console.log(data);
          this.authService.loggedINUSer=data;
          debugger;
          this.authService.isLoggedIn=true;
          this.authService.hasUserLoggedIN=of(true);
          localStorage.setItem('LoginInfo', JSON.stringify(data));
          let redirect = this.authService.redirectUrl ? this.authService.redirectUrl : '/';
          this.router.navigate([redirect]);
      },
      
      error=>{
        this.authService.isLoggedIn=false;
        this.messageService.add({severity:'error', summary:'Login Failed!!', detail:''});
        console.log(error);
      }
      );
    }
  //  this.message = 'Trying to log in ...';
  // //   console.log(this.loginUser);
  // //   this.authService.Authnticate(this.loginUser);
  // //  // this.messageService.add({severity:'error', summary:'Login Failed!!', detail:''});
  // //   let redirect = this.authService.redirectUrl ? this.authService.redirectUrl : '/';

  //   // Redirect the user
  //   this.router.navigate([redirect]);
    // this.authService.login(this.loginUser).subscribe(data => {
    //   alert(data);
    //   if (this.authService.isLoggedIn) {
    //     alert(this.authService.isLoggedIn);
    //     let redirect = this.authService.redirectUrl ? this.authService.redirectUrl : '/';
    //     this.router.navigate([redirect]);
    //   }
    //   else
    //   {
    //    // this.messageService.add({severity:'error', summary:'Login Failed!!', detail:''});
    //   }
    // });
  }
forgotPassword()
{
  //alert("hi");
  this.router.navigate(['/forgotpassword']);
}
  logout() {
    this.authService.logout();
   // this.setMessage();
    this.router.navigate(['/login']);
  }
}