import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { UserLogin } from 'src/app/Model/UserLogin';
import { delay } from 'rxjs/operators';


@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['../login.component.css'],
})
export class ForgotpasswordComponent implements OnInit {
  loginInfo:UserLogin
  constructor(public authService: AuthService, public router:Router,private messageService:MessageService) { }

  ngOnInit() {
    this.loginInfo=new UserLogin();
  }
  resetpassword()
  {
    debugger;
    this.loginInfo.isForgotPassword=true;
    this.authService.ResetPassword(this.loginInfo).subscribe(data=>
      {
        this.messageService.add({severity:'info', summary:data.Message, detail:''});
        
        //delay(5000);
       // this.router.navigate(['/login']);
       // alert(data.Message);
      }
      );
      //this.router.navigate['/login'];
  }
  messageCloded(event)
  {
    this.router.navigate(['/login']); 
  } 
}
