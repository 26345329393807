import { Component, OnInit } from '@angular/core';
import { PCN } from 'src/app/Model/PCN';
import { Department } from 'src/app/Model/department';
import { CommonService  } from "../../../services/common.service"
import { PCNService } from 'src/app/services/requests/pcn/pcn.service';
import { Approver } from 'src/app/Model/Approver';
import { UserService } from 'src/app/services/user.service';
import {Message} from 'primeng//api';
import {MessageService} from 'primeng/api';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { User } from 'src/app/Model/User';
import { AlertPromise } from 'selenium-webdriver';
import { of } from 'rxjs';

@Component({
  selector: 'app-new-pcn',
  templateUrl: './new-pcn.component.html',
  styleUrls: ['./new-pcn.component.css'],
  providers: [MessageService]
})
export class NewPCNComponent implements OnInit {
  public request:PCN;
  departmentList: Department[];
  selectedDept:Department;
  priorityList: any;
  verticalList: any;
  reviewPCN=true;
  approverList:Array<Approver>;
  firstLevelApprover:Approver
  constructor(private commonSrv:CommonService, private pcnSrv:PCNService,private userSrv:UserService,
    private messageService: MessageService, private router:Router,private authSrv:AuthService ) { }

  ngOnInit() {
    this.verticalList=[
      {label:'NA', value:'NA'},
      {label:'Retail ops-Pri', value:'Pri'},
      {label:'Retail ops-Ter', value:'Ter'},
      {label:'Retail ops-SMB', value:'SMB'},
      {label:'Retail ops-SMB', value:'SMB'},
      {label:'Retail ops-Oth', value:'Oth'},
      {label:'Marketing-BTL', value:'BTL'},
      {label:'Marketing-ATL', value:'ATL'},
      {label:'Marketing-Dig', value:'Dig'},
      {label:'Marketing-PR', value:'PR'},
      {label:'Marketing-Age', value:'Age'},
      {label:'Marketing-Oth', value:'Oth'},
      {label:'Product supp-Fan', value:'Fan'},
      {label:'Product supp-PCB', value:'PCB'},
      {label:'Product supp-Acc', value:'Acc'},
      {label:'CS-Ret', value:'Ret'},
      {label:'CS-Lbr', value:'Lbr'},
      {label:'CS-Spr', value:'Spr'},
      {label:'CS-CCC', value:'CCC'},
      {label:'CS-Oth', value:'Oth'},
      {label:'Log-Pri', value:'Pri'},
      {label:'Log-Sec', value:'Sec'},
      {label:'Log-W/h', value:'W/h'},
      {label:'Log-Slog', value:'Slog'},
      {label:'Log-Oth', value:'Oth'},
      {label:'Fin-Int', value:'Int'},
      {label:'Fin-Oth', value:'Oth'},
      {label:'HR&Admin-Adm', value:'Adm'},
      {label:'HR&Admin-Con', value:'Con'},
      {label:'HR&Admin-ITF', value:'ITF'},
      {label:'HR&Admin-Trv', value:'Trv'},
      {label:'HR&Admin-Oth', value:'Oth'},

   ]
   
   this.getDropdownData();
    this.request=new PCN();
   // this.getNewPCNNo();
    this.getAllApprovers();
  }
  getDropdownData()
  {
    this.commonSrv.GetAllDepartments().subscribe(data=>{
      this.departmentList = data;
    
    }) ;
    
  }
  getNewPCNNo()
  {
    this.pcnSrv.getNewPCNNo().subscribe(data=>{
   
      this.request.PCNNo=data.toString();
     console.log(data);
    }) ;
    
  }
  savePCN()
  {
    //this.messageService.add({severity:'success', summary:'PCN Created!!', detail:''});
    //return;
    // this.getAllApprovers();
    // this.reviewPCN=true;
  }
  uploadedFiles: any[] = [];
  uploadAttachment(event)
  {
    //alert("done");
    for(let file of event.files) { 
      this.uploadedFiles.push(file); 
      console.log(this.uploadedFiles); 
      } 
     this.pcnSrv.UploadAttachments(this.uploadedFiles,0).subscribe(data=>{
       this.request.Attachments=data;
     });

  }
 
  savePCNNext(status)
  {

   //alert(this.firstLevelApprover.ApproverName);
  // this.firstLevelApprover.PCNAPPID=0;
  //  console.log("test"+this.firstLevelApprover.PCNAPPID);
   // this.request.Attachements=this.uploadedFiles;
   // console.log(this.request.Attachements); 
    this.request.DeptID=this.selectedDept.DeptID;
    this.request.DeptName=this.selectedDept.DeptName;
    this.request.InitiatorID=this.authSrv.loggedINUSer.UserID;
    this.request.CreatedBy=this.authSrv.loggedINUSer.UserID;
    this.request.ParentPCNID=0;
    this.request.Priority="Normal";
    this.request.InitiatorName=this.authSrv.getUserName();
    this.request.ModifiedBy=this.authSrv.loggedINUSer.UserID;
    this.request.Status=status;
    //this.request.ModifiedDate=new Date();
   // this.request.ApproversList=this.approverList;
   this.request.ApproversList=new Array<Approver>();
  // this.request.ApproversList.push(this.firstLevelApprover);
 
  // this.request.FirstLevelApprover=this.firstLevelApprover;
  // this.request.FirstLevelApprover.Mandatory=true;
   this.request.FirstApproverID=this.firstLevelApprover.ApproverID;
 // alert(this.request.FirstLevelApprover.ApproverName);
  //this.request.ApproversList=this.approverList;
  //this.approverList.push(this.firstLevelApprover);
   this.approverList.forEach((approver) => {
   // this.testVariable = e;
      if(approver.NA || approver.FYI||approver.Mandatory)
      {
       // approver.DateOfAction=null;
        this.request.ApproversList.push(approver);
       // alert(this.request.PCNNo);
      }
    })
  
    //this.request.ApproversList.push(this.firstLevelApprover);
    
    this.authSrv.blocked=of(true);
    this.pcnSrv.SavePCN( this.request).subscribe(data=>{
   //  console.log(data);
    this.authSrv.blocked=of(false);;
    this.messageService.add({severity:'success', summary:'New PCN Request Submitted!!', detail:''});
    this.router.navigateByUrl('/');
    // alert("PCN Saved!!");
    }) ;
  }
  goback()
  {
    this.router.navigateByUrl('/');
  }
getAllApprovers()
{
  // let rq=new Pcnrequest();
  // rq.InitiatorName="dhirendra"
  // this.Service.SavePCN(rq).subscribe(data=>{
   
  //   console.log("AllAlerts");
  // }) ;

  this.pcnSrv.GetAllApprovers(1).subscribe(data=>{
    this.approverList = data;
    //console.log("AllAlerts",this.ApproverList[0].Mandatory);
  }) ;
}
usersList:Array<Approver>
search(event) {
  this.userSrv.lookupUsers(event.query).subscribe(data => {
      this.usersList = data;
      console.log(this.usersList);
  });
}
approverChangedMan(approver:Approver)
{
  approver.FYI=false;
}
approverChangedFYI(approver:Approver)
{
  approver.Mandatory=false;
}
}
