export class UserLogin
{
    UserName:string
    UserID:number
    Password:string
    ConfirmPassword:string
    Token:string
    isForgotPassword:boolean
    isChangePassword:boolean
    Message:string
    FirstLogin:boolean
}