import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import {MenuModule} from 'primeng/menu';
import {MenuItem} from 'primeng/api';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { of } from 'rxjs';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  items: MenuItem[]
  constructor(private authSrv:AuthService,private router: Router) { }

  ngOnInit() {
    this.items = [
      {label: 'My Profile', icon: 'fa fa-user', command: () => {
          //this.update();
      }},
      // {label: 'Settings', icon: 'fa fa-cog', command: () => {
      //    // this.delete();
      // }},
      {label: 'Change Password', icon: 'fa  fa-key', command: () => {
        this.changepassword();
    }},
      {label: 'Logout', icon: 'fa fa-sign-out', command: () => {
         this.logout();
     }},
     
  ];
}
logout() {
  this.authSrv.logout();
 
 // this.setMessage();
  this.router.navigate(['/login']);
}
changepassword() {
  
 
 // this.setMessage();
  this.router.navigate(['/changepassword']);
}
}
//   Logout(event)
//   {
//     // this.authSrv.isLoggedIn.subscribe(data=>
//     //   {
//     //     alert(data);
//     //   }
//     //   ) ;
    
//     this.authSrv.logout();
//     this.router.navigate(['login']);

//   }
// }
