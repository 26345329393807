import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { tap, delay } from 'rxjs/operators';
import { UserLogin } from '../Model/UserLogin';
import { HttpClient } from '@angular/common/http';
import { User } from '../Model/User';
import { environment } from 'src/environments/environment';
import { error } from '@angular/compiler/src/util';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isLoggedIn = false;
  hasUserLoggedIN:Observable<boolean>=of(false);
  blocked:Observable<boolean>=of(false);
  loggedINUSer:User;
  loginStatusMessage:string;
  constructor(private httpClient:HttpClient){
    var strUserinfo = localStorage.getItem('LoginInfo');
    this.loggedINUSer=JSON.parse(strUserinfo);
    if(this.loggedINUSer)
    {
     // this.loggedINUSer=J
      this.hasUserLoggedIN=of(true);
      this.isLoggedIn=true;

    }
    else
    {
      this.hasUserLoggedIN=of(false);
      this.isLoggedIn=false;
    }
  }
  // store the URL so we can redirect after logging in
  redirectUrl: string;

  login(loginInfo:UserLogin): Observable<boolean> {

     
    //this.Authnticate(loginInfo);
    return(of(this.isLoggedIn))
    return of(true).pipe(
      delay(1000),
      tap(val => this.isLoggedIn = true)
    // this.Authnticate()
    );
  }
Authnticate(loginInfo:UserLogin)
{
    return this.httpClient.post<User>(environment.apiEndPoint+"Login",loginInfo);
}
ResetPassword(loginInfo:UserLogin)
{
    return this.httpClient.post<UserLogin>(environment.apiEndPoint+"Login/ResetPassword",loginInfo);
}
getUserName()
{
  return this.loggedINUSer.FirstName + " " +this.loggedINUSer.LastName;
}
  logout(): void {
    localStorage.removeItem('LoginInfo');
    this.hasUserLoggedIN=of(false);
    this.isLoggedIn = false;
  }
}