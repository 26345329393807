export class User {
UserID:number
EmployeeCode:string
UserName:string
Password:string
LastName:string
FirstName:string
MiddleNAme:string
Band:string
JobTitle:string
EmailID:string
PhoneNo:string
DeptID:number
DeptName:string
ManagerID:number
ManagerName:string
Status:boolean
FirstLogin:boolean
PCNModule:boolean
POModule:boolean
ExpenseModule:boolean
LogisticsModule:boolean
AdminModule:boolean
ModifiedDate:string
CreatedDate:string
}