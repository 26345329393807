import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/Model/User';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent implements OnInit {
  usersList:User[]
  constructor(private userSrv:UserService, private router:Router, private messageService:MessageService) { }
  
  ngOnInit() {
      this.userSrv.getUsers().subscribe(data=>{
          this.usersList=data;
      });
  }
   isEdit=false;
   closeEdit()
   {
     this.isEdit=false;
   }
  editUser(user:User)
  {
     this.userSrv.editUser=user;
     this.isEdit=true;
    // this.router.navigate(["/edituser"]);
    //alert('hi');
  }

  deleteUser(user:User)
  {
    this.userSrv.deleteUser(user.UserID).subscribe(data=>
    {
      const index: number = this.usersList.indexOf(user);
      if (index !== -1) {
        this.usersList.splice(index, 1);
      }  
      this.messageService.add({severity:'info', summary:'Success', detail:'User deleted!', sticky: true});

    }
    )
  }
}
