import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AllRequestsComponent } from './Requests/List/all-requests/all-requests.component';
import { NewPCNComponent } from './Requests/PCN/new-pcn/new-pcn.component'; 
import { AuthGuard }  from './auth/auth.guard';
import { LoginComponent } from './auth/login/login.component';
import { LeftComponent } from './menu/left/left.component';
import { ReviewComponent } from './Requests/Edit/review/review.component';
import { PendingForApprovalComponent } from './pending-for-approval/pending-for-approval.component';
import { EditPCNComponent } from './Requests/edit-pcn/edit-pcn.component';
import { ForgotpasswordComponent } from './auth/login/forgotpassword/forgotpassword.component';
import { ChangepasswordComponent } from './auth/login/changepassword/changepassword.component';
import { CommentsComponent } from './comments/comments.component';
import { ReviewPCNInitiatorComponent } from './review-pcninitiator/review-pcninitiator.component';
import { CreateComponent } from './Users/Create/create/create.component';
import { ListComponent } from './Users/List/list/list.component';
import { EditComponent } from './Users/Editt/edit/edit.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ViewPCNComponent } from './Requests/view-pcn/view-pcn.component';




const routes: Routes = [
  {path: "" , component:AllRequestsComponent,pathMatch:'full',canActivate: [AuthGuard]} ,
  {path: "newpcn" , component:NewPCNComponent,canActivate: [AuthGuard]},
  {path: "editpcn" , component:EditPCNComponent,canActivate: [AuthGuard]},
  {path: "review/:pcnid" , component:ReviewComponent,canActivate: [AuthGuard]},
  {path: "review" , component:ReviewComponent,canActivate: [AuthGuard]},
  {path: "dashboard" , component:DashboardComponent,canActivate: [AuthGuard]},
  {path: "initiatorreview" , component:ReviewPCNInitiatorComponent,canActivate: [AuthGuard]},
  {path: "initiatorreview/:pcnid" , component:ReviewPCNInitiatorComponent,canActivate: [AuthGuard]},
  {path: "createuser" , component:CreateComponent,canActivate: [AuthGuard]},
  { path: 'edituser', component: EditComponent },
  {path: "userslist" , component:ListComponent,canActivate: [AuthGuard]},
  {path: "pending" , component:PendingForApprovalComponent,canActivate: [AuthGuard]},
  {path: "viewpcn" , component:ViewPCNComponent,canActivate: [AuthGuard]},
  {path: "forgotpassword" , component:ForgotpasswordComponent},
  {path: "changepassword" , component:ChangepasswordComponent},
  {path: "changepassword/:token" , component:ChangepasswordComponent},
  {path: "login" , component:LoginComponent},
  {path: "comments" , component:CommentsComponent},
  { path: '**', component:AllRequestsComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
