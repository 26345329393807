import { Component, OnInit } from '@angular/core';
import { PCNService } from 'src/app/services/requests/pcn/pcn.service';
import { PCN } from 'src/app/Model/PCN';
import { Approver } from 'src/app/Model/Approver';
import { ActivatedRoute, Router } from '@angular/router';
import { of } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
@Component({
  selector: 'app-view-pcn',
  templateUrl: './view-pcn.component.html',
  styleUrls: ['./view-pcn.component.css']
})
export class ViewPCNComponent implements OnInit {

  reviewPCN:PCN
  
 constructor(private route:ActivatedRoute, private pcnSrv:PCNService, private router:Router,private authSrv:AuthService ) { 
   let temp=  this.route.snapshot.params.pcnid;
  // alert(temp);
   if(temp!=null)
   pcnSrv.editPcnNo=temp;
//  pcnSrv.editPCN=
  // pcnid= this.route.snapshot.params.token;
 }
 isDataLoaded:boolean=false;
 ngOnInit() {
  // alert("hello ");
     this.pcnSrv.getPCNDetail(false).subscribe( data=>{
       this.reviewPCN=data;
       this.isDataLoaded=true;
      //debugger;
      // this.approver=this.reviewPCN.FirstLevelApprover;
      // console.log(this.reviewPCN);
     });
 }
 close()
 {
  this.router.navigateByUrl('/');
 }
}
