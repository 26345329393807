import { Component, OnInit } from '@angular/core';
import { UserService  } from "../../../services/user.service";
import { PCNService  } from "../../../services/requests/pcn/pcn.service";
import { PCN } from "../../../Model/PCN";
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { Approver } from 'src/app/Model/Approver';
import { MenuItem } from 'primeng/api';
import { PCNRemarks } from 'src/app/Model/Remark';
//import {DialogService} from 'primeng/api';
@Component({
  selector: 'app-all-requests',
  templateUrl: './all-requests.component.html',
  styleUrls: ['./all-requests.component.css'],
  //providers: [DialogService]
})
export class AllRequestsComponent implements OnInit {

  constructor(private userSrv:UserService,private pcnSrv:PCNService,private authSrv:AuthService,private router:Router) { }
  pcnList:PCN [];
  items: MenuItem[];
  selectedStatus:string="Pending"
  selectedPcnList:PCN [];
  ngOnInit() {
    console.log(this.pcnSrv.getPCNList(this.authSrv.loggedINUSer.UserID));
    this.items = [
      {label: 'Pending ', icon: 'fa fa-tasks', command: () => {
          this.changeStatus("Pending");//Pending();
      }},
      {label: 'Approved ', icon: 'fa fa-check', command: () => {
        this.changeStatus("Approved");//Pending();
      }},
      {label: 'Rejected', icon: 'fa fa-fw fa-close', command: () => {
        this.changeStatus("Rejected");
      }}
  ];
    this.pcnSrv.getPCNList(this.authSrv.loggedINUSer.UserID).subscribe(data=>{
      console.log(data)
      debugger;
      this.pcnList=data;
     // this.pcnList.filter((pcn: PCN) => pcn.Status === "Approved");
    //   this.pcnList.filter(obj=>
    //     {
    //       obj.Status==="Approved";
    //     }
    //     );
     }) ;
  }
  changeStatus(status)
  {
    debugger;
    this.selectedStatus=status;
    this.pcnList.filter(obj=>
    {
      obj.Status===status;
    });
  }
  
  review(pcnID)
  {
    this.pcnSrv.editPcnNo=pcnID;
    this.router.navigateByUrl('/review');
  }
  edit(pcn:PCN)
  {
    //this.pcnSrv.editPCN=pcn;
    //alert(pcnid);
    this.pcnSrv.editPcnNo=pcn.PCNID;
    if(pcn.Status=="Review")
    {
      this.router.navigateByUrl('/initiatorreview');
    }
    else
    {
       this.router.navigateByUrl('/editpcn');
    }
  }
  display:boolean=false;
  lstApp:Array<Approver>;
  lstRemarks:Array<PCNRemarks>;
viewStatus(pcn:PCN)
{
 //   debugger;
  //  this.lstApp=pcn.ApproversList;
  //  this.lstRemarks=pcn.RemarksList;
   // alert(pcn.ApproversList[0].ApproverName);
  //  this.display = true;
   this.pcnSrv.editPcnNo=pcn.PCNID;
    
      this.router.navigateByUrl('/viewpcn');
    
  }
  deletePCN(pcn:PCN)
  {
    this.pcnSrv.deletePCN(pcn.PCNID).subscribe()
    {
      const index: number = this.pcnList.indexOf(pcn);
      if (index !== -1) {
        this.pcnList.splice(index, 1);
      }  
       // this.router.navigate(["/"]);

    }
    //this.pcnList.pop(pcn);
  }

}
