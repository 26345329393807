import { Component, OnInit } from '@angular/core';
import {Validators,FormControl,FormGroup,FormBuilder} from '@angular/forms';
import {SelectItem} from 'primeng/api';
import {MessageService} from 'primeng/api';
import { User } from 'src/app/Model/User';
import { UserService } from 'src/app/services/user.service';
import { Output, EventEmitter } from '@angular/core';
@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class EditComponent implements OnInit {
  userform: FormGroup;
  user:User;
  submitted: boolean;
  
  statuses: SelectItem[];
  titles: SelectItem[];

  departments: SelectItem[];

  bands: SelectItem[];
  constructor(private fb: FormBuilder, private messageService: MessageService,private userSrv:UserService) { }

  ngOnInit() {
    this.user=this.userSrv.editUser;
    this.userform = this.fb.group({
      'firstname': new FormControl(this.user.FirstName, Validators.required),
      'lastname': new FormControl(this.user.LastName, Validators.required),
      'employeecode': new FormControl(this.user.EmployeeCode, Validators.required),
      'department': new FormControl(this.user.DeptName, Validators.required),
      'band': new FormControl(this.user.Band, Validators.required),
      'emailid': new FormControl(this.user.EmailID, Validators.email),
      'phonenumber': new FormControl(this.user.PhoneNo, Validators.required),
      
      'title': new FormControl(this.user.JobTitle, Validators.required),
      'PCNModule': new FormControl(this.user.PCNModule),
      'POModule': new  FormControl(this.user.POModule),
      'ExpenseModule': new FormControl(this.user.ExpenseModule),
      'LogisticsModule': new FormControl(this.user.LogisticsModule),
      'AdminModule': new FormControl(this.user.AdminModule),
     // 'password': new FormControl('', [Validators.required, Validators.minLength(6)]),
     // 'description': new FormControl(''),
    //  'gender': new FormControl('', Validators.required)
  });
  
  this.statuses = [];
  this.statuses.push({label:'Select Status', value:''});
  this.statuses.push({label:'Enabled', value:'1'});
  this.statuses.push({label:'Disabled', value:'0'});

  this.departments  = [];
  this.departments.push({label:'Select Department', value:''});
  this.departments.push({label:'App & Cloud', value:'App & Cloud'});
  this.departments.push({label:'Commercial', value:'Commercial'});
  this.departments.push({label:'CSD', value:'CSD'});
  this.departments.push({label:'Electro Mech', value:'Electro Mech'});
  this.departments.push({label:'Finance', value:'Finance'});
  this.departments.push({label:'HR&A', value:'HR&A'});
  this.departments.push({label:'IOT & Embedded', value:'IOT & Embedded'});
  this.departments.push({label:'IT', value:'IT'});
  this.departments.push({label:'Logistics', value:'Logistics'});
  this.departments.push({label:'Marketing Communication', value:'Marketing Communication'});
  this.departments.push({label:'Product Marketing', value:'Product Marketing'});
  this.departments.push({label:'PSI & Planning', value:'PSI & Planning'});
  this.departments.push({label:'Quality', value:'Quality'});
  this.departments.push({label:'Retail', value:'Retail'});

  this.bands  = [];
  this.bands.push({label:'Select Band', value:''});
  this.bands.push({label:'E', value:'E'});
  this.bands.push({label:'M', value:'M'});
  this.bands.push({label:'S', value:'S'});
  

  this.titles  = [];
  this.titles.push({label:'Select Title', value:''});
  this.titles.push({label:'CEO', value:'CEO'});
  this.titles.push({label:'Deputy General Manager', value:'Deputy General Manager'});
  this.titles.push({label:'Deputy Manager', value:'Deputy Manager'});
  this.titles.push({label:'Director', value:'Director'});
  this.titles.push({label:'Engineer', value:'Engineer'});
  this.titles.push({label:'Executive', value:'Executive'});
  this.titles.push({label:'General Manager', value:'General Manager'});
  this.titles.push({label:'Lead Engineer', value:'Lead Engineer'});
  this.titles.push({label:'Manager', value:'Manager'});
  this.titles.push({label:'NA', value:'NA'});
  this.titles.push({label:'Senior Engineer', value:'Senior Engineer'});
  this.titles.push({label:'Senior Executive ', value:'Senior Executive '});
  this.titles.push({label:'Senior General Manager', value:'Senior General Manager'});
  this.titles.push({label:'Senior Manager', value:'Senior Manager'});
  this.titles.push({label:'Senior Vice President', value:'Senior Vice President'});
  this.titles.push({label:'Territory Executive', value:'Territory Executive'});
  this.titles.push({label:'Vice President', value:'Vice President'});
 
  
  }
  //isEdit=false;
  @Output() closedEvent = new EventEmitter();
  closeform()
  {
    this.closedEvent.next();
   // this.isEdit=false;
  }
  onSubmit(value: string) {
    //this.submitted = true;
   /// alert(this.user.FirstName);
   this.user.FirstName= this.userform.get("firstname").value;
      this.user.LastName= this.userform.get("lastname").value;
      this.user.EmployeeCode=this.userform.get("employeecode").value;
      this.user.DeptName=this.userform.get("department").value;
      this.user.EmailID=this.userform.get("emailid").value;
      this.user.PhoneNo=this.userform.get("phonenumber").value;
      this.user.Band=this.userform.get("band").value;
      this.user.PCNModule=this.userform.get("PCNModule").value;
      this.user.POModule=this.userform.get("POModule").value;
      this.user.ExpenseModule=this.userform.get("ExpenseModule").value;
      this.user.LogisticsModule=this.userform.get("LogisticsModule").value;
      this.user.AdminModule=this.userform.get("AdminModule").value;
      this.user.JobTitle=this.userform.get("title").value;
    this.userSrv.saveUser(this.user).subscribe(data=>{
      //  alert("User Saved!!")
        this.messageService.add({severity:'info', summary:'Success', detail:'User Updated!', sticky: true});
      });

  }

}
