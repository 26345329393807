import { Component, OnInit } from '@angular/core';
import {MenuModule} from 'primeng/menu';
import {MenuItem} from 'primeng/api';
import {PanelMenuModule} from 'primeng/panelmenu';
import { Observable, of } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
@Component({
  selector: 'app-left',
  templateUrl: './left.component.html',
  styleUrls: ['./left.component.css']
})

export class LeftComponent implements OnInit {
  public isLoggedIn;
  constructor(private authSrv:AuthService) {
  
   }
  private items: MenuItem[];

    ngOnInit() {
      this.isLoggedIn=this.authSrv.isLoggedIn;
      // this.authSrv.isLoggedIn.subscribe(data=>
      //   {
      //     this.isLoggedIn=data;
      //   }
      //   ) ;
      

        this.items = [{
            label: 'PCN',disabled: this.authSrv.loggedINUSer==null ? false : !this.authSrv.loggedINUSer.PCNModule,
            items: [
              {label: 'Create New PCN', icon: 'pi pi-fw pi-plus', routerLink:['/newpcn']},
              {label: 'My Requests', icon: 'pi pi-pi pi-bars',  routerLink:['/']},
              {label: 'Pending PCN Approvals', icon: 'pi pi-pi pi-bars', routerLink:['/pending']},
            ]
            ,expanded:this.authSrv.loggedINUSer==null ? false : this.authSrv.loggedINUSer.PCNModule
            
        },
        {
            label: 'Purchase Order',disabled:this.authSrv.loggedINUSer==null ? false : !this.authSrv.loggedINUSer.POModule,
            items: [
              {label: 'Create New PO', icon: 'pi pi-fw pi-plus'},
              {label: 'My PO List', icon: 'pi pi-pi pi-bars'},
              {label: 'Pending PO Approvals', icon: 'pi pi-pi pi-bars'},
            ]
        },
        {
          label: 'Expenses Reimbursement',disabled:this.authSrv.loggedINUSer==null ? false : !this.authSrv.loggedINUSer.ExpenseModule,
          items: [
            {label: 'Submit New Expense', icon: 'pi pi-fw pi-plus'},
            {label: 'My Expense List', icon: 'pi pi-pi pi-bars'},
            {label: 'Pending Approvals', icon: 'pi pi-pi pi-bars' },
          ]
       },
       {
        label: 'Logistics Department',disabled:this.authSrv.loggedINUSer==null ? false : !this.authSrv.loggedINUSer.LogisticsModule,
        items: [
          {label: 'Create New PO', icon: 'pi pi-fw pi-plus'},
          {label: 'My PO List', icon: 'pi pi-pi pi-bars'},
          {label: 'Pending PO Approvals', icon: 'pi pi-pi pi-bars'},
        ]
       },
       {
        label: 'Admin Panel',disabled:this.authSrv.loggedINUSer==null ? false : !this.authSrv.loggedINUSer.AdminModule,
        items: [
          {label: 'Create New User', icon: 'pi pi-fw pi-plus', routerLink:['/createuser']},
          {label: 'Users List', icon: 'pi pi-pi pi-bars',routerLink:['/userslist']},
        
        ]
    }

      ]
        
        ;
    }
}



